.logo {
  height: 64px;
}

.profile {
  height: 64px;
  float: right;
  background-color: #001529;
  color: white;
}

.profile-ant-avatar {
  margin-top: 8px;
  margin-left: 8px;
  padding-left: 8px;
  padding-bottom: 4px;
  background-color: coral;
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}

.vertical-initial {
  vertical-align: initial;
}

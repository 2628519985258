@import "../../node_modules/bootstrap/scss/bootstrap.scss";

#root,
.App {
  height: 100%;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
